import React from 'react'
import Box from '@material-ui/core/Box'

import ContentHeader from '../components/Typography/ContentHeader'

// import SectionStyle1 from '../components/curves/SectionStyle1'
import SEO from '../layouts/Seo'

import GeneralCarousel from '../contents/carousel/GeneralCarousel'

const IthalPuntaPage = () => {
  return (
    <>
      <SEO
        title='İthal Punta Kaynaklı Teller - Erez Kaynak'
        description='Firmamız ithal 6x6mm, 12x12mm göz galvaniz ve paslanmaz punta kaynaklı tel satışı yapmaktadır.'
        keywords='6x6mm punta kaynaklı tel,12x12mm hasır tel,12.7x12.7mm puntalı tel,12.7x12.7mm punta kaynaklı tel,küçük göz punta kaynaklı tel,havalandırma teli'
        url='ithal-punta-kaynakli-teller'
        // imgurl={getImageByName('hasirtel13').sm.src}
      />
      <Box paddingX={3} paddingY={5}>
        <ContentHeader title='İthal Punta Kaynaklı Teller' description='Türkiyenin her yerine gönderim yapılmaktadır.' />
      </Box>
      <GeneralCarousel />
    </>
  )
}

export default IthalPuntaPage
